import React from 'react'

import { FilterItem as Item } from './FilterItem'

import styles from './filter.module.scss'

interface Props {
  items: string[]
  onSelectionChange: (selected: string[]) => void
}

export const MultiSelectFilter: React.FC<Props> = ({ items, onSelectionChange }) => {
  const [selection, setselection] = React.useState(Array<string>())

  const selectionUpdated = (item: string) => {
    const newSelection = updateSelectionOnItemClick(selection, item)
    setselection(newSelection)
    onSelectionChange(newSelection)
  }

  return (
    <ul className={styles.items}>
      {items.map(item => {
        const status = (
          (selection.indexOf(item) !== -1 && 'active') ||
          'disabled'
        )
        return <Item key={item} item={item} status={status} onClick={selectionUpdated}/>
      })}
    </ul>
  )
}

const updateSelectionOnItemClick = (selection: string[], item: string) => {
  if (selection.indexOf(item) !== -1) {
    return [...selection.filter(selItem => selItem !== item)]
  } else {
    return [...selection, item]
  }
}
