import React, { useCallback, useMemo } from 'react'

import { sortByNumberAsc, range } from '../../utils'

import styles from './pagination.module.scss'

export interface Props {
  currentPage: number
  maxPage: number
  maxToShowAll?: number
  onPageClick: (page: number) => void
  onNextClick: VoidFunction
  onPreviousClick: VoidFunction
}

export const Pagination: React.FC<Props> = ({
  currentPage,
  maxPage,
  maxToShowAll,
  onPageClick: onPageClickProp,
  onNextClick: onNextClickProp,
  onPreviousClick: onPreviousClickProp,
}) => {
  const { page, pagesToShow, onPageClick, onNextClick, onPreviousClick } = usePaginationComponent({
    currentPage,
    maxPage,
    maxToShowAll,
    onPageClick: onPageClickProp,
    onNextClick: onNextClickProp,
    onPreviousClick: onPreviousClickProp,
  })

  let prev = pagesToShow[0]

  return maxPage > 1 ? (
    <ul className={styles.pagination}>
      <li className={genTextClass(page === 1)} onClick={onPreviousClick}>PREVIOUS</li>
      {pagesToShow.map(num => {
        const returnValue = []
        if (num - prev > 1) {
          returnValue.push(
            <li key={`dots-${num - prev}`} className={genDotsClass()}>&middot;&middot;&middot;</li>,
          )
        }
        returnValue.push(
          <li
            key={`page-${num}`}
            className={genNumberClass(page === num)}
            onClick={onPageClick(num)}
          >
            {num}
          </li>,
        )
        prev = num
        return returnValue
      })}
      <li className={genTextClass(page === maxPage)} onClick={onNextClick}>NEXT</li>
    </ul>
  ) : null
}

const genDotsClass = () => (
  `h6 ${styles.paginationItem} ${styles.paginationItemNumber} ${styles.paginationItemDots}`
)

const genNumberClass = (isActive = false) => (
  `h6 ${styles.paginationItem} ${styles.paginationItemNumber} ${
    isActive ? styles.paginationItemActive : styles.paginationItemHover
  }`
)

const genTextClass = (isDisabled: boolean) => (
  `h6 ${styles.paginationItem} ${
    isDisabled ? styles.paginationItemDisabled : styles.paginationItemHover
  }`
)

const usePaginationComponent = ({
  currentPage,
  maxPage,
  maxToShowAll = 5,
  onPageClick: onPageClickProp,
  onNextClick: onNextClickProp,
  onPreviousClick: onPreviousClickProp,
}: Props) => {
  const page = useMemo(() => Math.min(Math.max(1, currentPage), maxPage), [currentPage, maxPage])
  const pagesToShow = useMemo(() => {
    if (maxPage <= maxToShowAll) {
      return range(maxPage)
    }
    // if not all pages are shown, show first two, last two, and current +/- 1
    const uniquePages = new Set<number>([
      1, // first
      Math.min(2, maxPage), // second
      Math.max(1, page - 1), // current - 1
      page, // current
      Math.min(maxPage, page + 1), // current + 1
      Math.max(1, maxPage - 1), // second to last
      maxPage, // last
    ])
    return [...uniquePages].sort(sortByNumberAsc)
  }, [page, maxPage, maxToShowAll])

  const onPageClick = useCallback((num: number) => () => {
    // if current page is clicked, do nothing
    if (num === page) {
      return
    }
    onPageClickProp(num)
  }, [page, onPageClickProp])

  const onNextClick = useCallback(() => {
    // if next is clicked while on last page, do nothing
    if (page === maxPage) {
      return
    }
    onNextClickProp()
  }, [page, maxPage, onNextClickProp])

  const onPreviousClick = useCallback(() => {
    // if previous is clicked while on first page, do nothing
    if (page === 1) {
      return
    }
    onPreviousClickProp()
  }, [page, onPreviousClickProp])

  return {
    page,
    pagesToShow,
    onPageClick,
    onPreviousClick,
    onNextClick,
  }
}
