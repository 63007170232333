import React from 'react'

import styles from './scss/article.module.scss'

interface Props {
  readTimeMin: number
}

export const ArticleEstReadTime: React.FC<Props> = ({ readTimeMin }) =>
  <div className={`${styles.readTime} light-copy pink`}>
    <img
      src='/images/icons/clock-icon.svg'
      className='no-margin-btm'
      alt='Estimate reading time'
    />
    {`${readTimeMin}min read`}
  </div>
