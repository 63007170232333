import React from 'react'
import Img from 'gatsby-image'

import { useEnumerated } from '../../hooks'

import styles from './scss/article-content.module.scss'

interface Props {
  firstP: React.ReactNode
  secondP: React.ReactNode
  thirdP?: React.ReactNode
  secondaryImage: FluidImage
  tertiaryImage?: FluidImage
  enumerateParagraphs?: boolean
}

export const ArticleContent: React.FC<Props> = ({
  firstP,
  secondP,
  thirdP,
  secondaryImage,
  tertiaryImage,
  enumerateParagraphs = false,
}) => {
  // prepare headings for enumeration which is used through `styles.contentEnumerated`,
  // only if `enumerateParagraphs` is set to true
  useEnumerated()

  return (
    <section className={`${styles.content} ${enumerateParagraphs ? styles.contentEnumerated : ''}`}>
      <div className='container'>
        {firstP}
      </div>
      <Img
        className={styles.paragraphImage}
        fluid={secondaryImage.fluid}
        alt={secondaryImage.alt || 'Article secondary image'}
      />
      <div className='container'>
        {secondP}
      </div>
      {tertiaryImage && (
        <Img
          className={styles.paragraphImage}
          fluid={tertiaryImage.fluid}
          alt={tertiaryImage.alt || 'Article tertiary image'}
        />
      )}
      <div className='container'>
        {thirdP}
      </div>
    </section>
  )
}
