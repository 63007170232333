import React from 'react'
import Img from 'gatsby-image'

import Addon from '../Addons'
import styles from './hero.module.scss'

interface Props {
  img?: FluidImage
  headline?: string
  addon?: Addon
  className?: string
}

export const SmallHero: React.FC<Props> = ({ img, headline, addon, className }) => (
  <div className={genClassName(Boolean(img), className)}>
    <div className='container grid-x cell'>
      <div className='cell small-12 medium-10 large-8 medium-offset-1 large-offset-2'>
        {img && img.fluid && <Img fluid={img.fluid} alt={img.alt || 'Small hero image'}/>}
        {headline && <Headline headline={headline}/>}
      </div>
    </div>
    {addon && <Addon freeForm={addon}/>}
  </div>
)

interface HeadlineProps {
  headline: string
}

const Headline: React.FunctionComponent<HeadlineProps> = ({ headline }) => (
  <div className={styles.headlineContainer}>
    <h1 className='dark-bg'>{headline}</h1>
    <img src='/images/illustrations/small-hero-illustration.svg' alt='Background stars'/>
  </div>
)

const genClassName = (hasImg: boolean, className?: string) => {
  const classes = [styles.smallHero]
  hasImg && classes.push(styles.smallHeroWithImg)
  className && classes.push(className)
  return classes.join(' ')
}
