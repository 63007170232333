import React from 'react'

import styles from './filter.module.scss'

type Status = 'active' | 'disabled'

interface Props {
  item: string
  status: Status
  onClick: (item: string) => void
}

export const FilterItem: React.FC<Props> = ({ item, status, onClick }) => (
  <li
    className={`${styles.item}
    ${itemStatusToClass(status)} paragraph`}
    onClick={() => onClick(item)}
  >
    {item}
  </li>
)

const itemStatusToClass = (status: Status) => (status === 'active' && styles.itemActive) || ''
