import React from 'react'
import { Link as ScrollLink } from 'react-scroll'

import Addons from '../Addons'
import { HeroIllustration as Illustration } from './HeroIllustration'

import styles from './hero.module.scss'

interface Props {
  headline: string
  subheadline: string
}

const headlinePositionClasses = `
  dark-bg cell small-12 medium-10 medium-offset-1 large-8 large-offset-2 text-center
`

export const Hero: React.FC<Props> = ({ headline, subheadline }) =>
  <section className={styles.hero}>
    <div className={`${styles.container} container`}>
      <div className={styles.stars}>
        <img src='/images/illustrations/star-cluster.svg' alt='Star cluster illustration'/>
      </div>
      {(headline || subheadline) &&
        <div className={`${styles.copy} grid-x`}>
          {headline &&
            <h1 className={headlinePositionClasses}>
              { headline }
            </h1>
          }
          {subheadline.trim() && <h5
            className={headlinePositionClasses}>{ subheadline }</h5>}
        </div>
      }
      <div className={styles.planetSurface}>
        <img
          src='/images/illustrations/hero-illustration.svg'
          alt='Planet star port illustration'
          className={styles.planetSurfaceIllustration}
        />
      </div>
    </div>
    <Illustration/>
    <Addons freeForm={{
      addon: (
        <ScrollLink to='first-section' smooth={true} className='show-for-large'>
          <img src='/images/arrow-down.svg' alt='Scroll to content'/>
        </ScrollLink>
      ),
      className: styles.arrowAddon,
    }}/>
  </section>
