import React, { useCallback, useState } from 'react'

import Card from '../../components/Card'
import { Triangle } from '../../components/icons/Triangle'
import Filter from '../../components/MultiSelectFilter'

import styles from './blog.module.scss'

interface Props {
  articles: Article[]
  onSelectionChange: (selected: string[]) => void
}

export const TagCard: React.FC<Props> = ({ articles, onSelectionChange }) => {
  // this is used through css classes
  const [isVisible, setIsVisible] = useState(false)

  const toggleFilterVisibility = useCallback(() => {
    setIsVisible(s => !s)
  }, [setIsVisible])

  const genVisibilityClasses = useCallback((classBase: string) => (
    `${styles[classBase]} ${!isVisible ? styles[`${classBase}Invisible`] : ''}`
  ), [isVisible])

  const items = extractTags(articles)

  return (
    <Card className={`${genVisibilityClasses('tagCard')} ${styles.sticky}`} extraShadow>
      <h5 onClick={toggleFilterVisibility}>
        <span>Filter by topic:</span>
        <span className={genVisibilityClasses('triangle')}>
          <Triangle/>
        </span>
      </h5>
      <div className={genVisibilityClasses('filterContainer')}>
        <Filter items={items} onSelectionChange={onSelectionChange}/>
      </div>
    </Card>
  )
}

/**
 * Get a list of all tags within all articles removing
 * duplicates in the process
 * @param articles
 */
const extractTags = (articles: Article[]): string[] => {
  const flattened = Array<string>()
  articles.forEach(
    a => flattened.push(...a.tags))
  return Array.from(
    new Set(flattened), // Unique items
  ).sort((a, b) => a.toLowerCase() < b.toLowerCase() ? -1 : 1)
}
