import React, { useCallback, useState, useEffect } from 'react'
import { Element, scroller } from 'react-scroll'

import ArticleList from '../../components/BlogArticleList'
import { SmallHero } from '../../components/Hero'
import { PaginationWithContext } from '../../components/Pagination'
import { PaginationProvider, usePaginationContext } from '../../context'
import Layout from '../../layout/Layout'
import Section from '../../layout/Section'
import { TagCard } from './TagCard'

import styles from './blog.module.scss'

const BLOG_TOP_ANCHOR_NAME = 'blog-top'

interface Props {
  articles: Article[]
  pageContext: PageContext
}

export const Blog: React.FC<Props> = ({ pageContext, articles }) => (
  <Layout pageContext={pageContext}>
    <SmallHero
      headline={pageContext.headline}
      addon={{
        addon: <img src='/images/illustrations/planet2.svg' alt='Hero illustration'/>,
        className: styles.heroAddon,
      }}
    />
    <Content articles={articles}/>
  </Layout>
)

type Filters = Set<string>
type ContentProps = Pick<Props, 'articles'>

const Content: React.FC<ContentProps> = ({ articles }) => {
  const {
    setFilters,
    onPageClick,
    onNextClick,
    onPreviousClick,
    activeArticles,
  } = useContent(articles)

  const onFilterChange = useCallback((filters: Filters) => {
    setFilters(filters)
  }, [setFilters])

  return (
    <PaginationProvider<Article>
      items={activeArticles}
      onPageClick={onPageClick}
      onNextClick={onNextClick}
      onPreviousClick={onPreviousClick}
    >
      <Section>
        <div className={styles.blog}>
          <Filter articles={articles} onFilterChange={onFilterChange}/>
          <div className={styles.list}>
            <Element name={BLOG_TOP_ANCHOR_NAME}/>
            <Articles/>
            <div className={styles.paginationContainer}>
              <PaginationWithContext/>
            </div>
          </div>
        </div>
      </Section>
    </PaginationProvider>
  )
}

interface FilterProps {
  articles: Article[]
  onFilterChange: (filters: Filters) => void
}
const Filter = ({ articles, onFilterChange }: FilterProps) => {
  const { setPage } = usePaginationContext()
  const onSelectionChange = useCallback((filters: string[]) => {
    setPage(1)
    onFilterChange(new Set(filters))
  }, [setPage, onFilterChange])

  return (
    <div className={styles.filter}>
      <TagCard articles={articles} onSelectionChange={onSelectionChange}/>
    </div>
  )
}

const Articles = () => {
  const { pageItems } = usePaginationContext()
  return <ArticleList articles={pageItems as Article[]}/>
}

const useContent = (articles: Article[]) => {
  const [activeArticles, setActiveArticles] = useState([...articles])
  const [filters, setFilters] = useState<Filters>(new Set())

  useEffect(() => {
    if (filters.size === 0) {
      setActiveArticles([...articles])
    } else {
      setActiveArticles(articles.filter(a => a.tags.some(tag => filters.has(tag))))
    }
  }, [articles, filters])

  const onPageChange = useCallback(() => {
    scrollToBlogTop()
  }, [])

  const onPageClick = useCallback(() => {
    onPageChange()
  }, [onPageChange])

  const onNextClick = useCallback(() => {
    onPageChange()
  }, [onPageChange])

  const onPreviousClick = useCallback(() => {
    onPageChange()
  }, [onPageChange])

  return {
    setFilters,
    onPageClick,
    onNextClick,
    onPreviousClick,
    activeArticles,
  }
}

const scrollToBlogTop = () => {
  scroller.scrollTo(BLOG_TOP_ANCHOR_NAME, { smooth: false, offset: -200 })
}
