import React, { useMemo } from 'react'

import Addons from '../Addons'

import styles from './card.module.scss'

export interface Props {
  className?: string
  noRounded?: boolean
  dark?: boolean
  extraShadow?: boolean
  flat?: boolean
  addon?: Addon
  addonTop?: AddonTop
  addonBottom?: AddonBottom
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}

export const Card: React.FC<Props> = ({
  children,
  className,
  noRounded,
  flat,
  dark,
  extraShadow,
  addon,
  addonTop,
  addonBottom,
  onMouseEnter,
  onMouseLeave,
}) => {
  const generatedClassName = useMemo(() => genClassName(
    className || '',
    noRounded || false,
    dark || false,
    flat || false,
    extraShadow || false,
  ), [className, noRounded, dark, flat, extraShadow])

  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={`${styles.card} ${generatedClassName}`}
    >
      {children}
      <Addons freeForm={addon} top={addonTop} bottom={addonBottom}/>
    </div>
  )
}

const genClassName = (
  className: string, noRounded: boolean, dark: boolean, flat: boolean, extraShadow: boolean,
): string => {
  const classes = [className]
  noRounded && classes.push(styles.cardNorounded)
  dark && classes.push(styles.cardDarkbg)
  extraShadow && classes.push(styles.cardExtraShadow)
  flat && classes.push(styles.cardNoshadow)
  return classes.join(' ')
}
