import React from 'react'
import { graphql } from 'gatsby'

import { processContext, extractArticles } from '../contentful'
import Blog from '../page-components/Blog'

interface Props {
  data: PageQueryData & ArticlesQueryData
}

const BlogPage: React.FC<Props> = ({ data }) => (
  <Blog pageContext={processContext(data)} articles={extractArticles(data)}/>
)

export const query = graphql`
  query {
    page: allContentfulPage(filter: {slug: {eq: "blog"}}) {
      edges {
        node {
          ... PageFragment
        }
      }
    }
    articles: allContentfulBlogArticle (filter: {pinkdroids: {eq: true}}, sort: {fields: [publishedDt], order: DESC}) {
      edges {
        node {
          ... BlogArticleFragment
        }
      }
    }
  }`

export default BlogPage
