import React from 'react'

import { ArticleListItem } from './ArticleListItem'

import styles from './blog-article-list.module.scss'

interface Props {
  articles: Article[]
  listItemWrapperClasses?: string
}

export const BlogArticleList: React.FC<Props> = ({ articles, listItemWrapperClasses }) =>
  <div className={styles.articleList}>
    {articles.map(a => (
      <div className={listItemWrapperClasses} key={a.slug}>
        <ArticleListItem key={a.slug} article={a}/>
      </div>
    ))}
  </div>
