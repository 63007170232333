import React from 'react'

import styles from './scss/article.module.scss'

interface Props {
  headline: string
}

export const ArticleHeadline: React.FC<Props> = ({ headline }) =>
  <div className='container grid-x'>
    <h1 className={
      `cell small-12 medium-10 large-9 medium-offset-1 large-offset-2 ${styles.articleHeadline}`
    }>
      {headline}
    </h1>
  </div>
