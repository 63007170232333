import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import { formatDate } from '../../utils'
import Card from '../Card'
import { ArticleEstReadTime, ArticleTags } from '../Article'

import styles from './blog-article-list.module.scss'

interface Props {
  article: Article
}

export const ArticleListItem: React.FC<Props> = ({ article }) => (
  <Link to={`/blog/${article.slug}/`}>
    <Card className={styles.article} extraShadow>
      <div className={styles.imageContainer}>
        <Img
          className={styles.image}
          fluid={article.listImage.fluid}
          alt={article.listImage.alt || imgAlt(article.headline)}
        />
      </div>
      <Content article={article}/>
    </Card>
  </Link>
)

const Content = ({ article }: Props) => (
  <div className={styles.content}>
    <div className={styles.info}>
      <span className='light-copy pink'>
        {formatDate(article.publishedDt)}
      </span>
      <ArticleEstReadTime readTimeMin={article.estimatedReadTimeMinutes}/>
    </div>
    <h3 className={`${styles.headline} no-margin-btm`}>
      {article.headline}
    </h3>
    <p className={`no-margin-btm ${styles.lead}`}>{article.lead}</p>
    <div className={styles.tagContainer}>
      <ArticleTags tags={article.tags}/>
    </div>
  </div>
)

const imgAlt = (headline: string): string => `${headline} image`
