import React from 'react'

import styles from './hero-illustration.module.scss'

export const HeroIllustration: React.FC = () => (
  <div className={styles.illustrationContainer}>
    <div className={styles.planet2}>
      <img src='/images/illustrations/planet2.svg' alt='Spaceship travelling'/>
    </div>
    <div className={styles.planet3}>
      <img src='/images/illustrations/planet3.svg' alt='Spaceship floating up and down'/>
    </div>
  </div>
)
