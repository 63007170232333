import React from 'react'

import { usePaginationContext } from '../../context'
import { Pagination } from './Pagination'

export const PaginationWithContext: React.FC = () => {
  const {
    currentPage,
    numOfPages,
    maxToShowAll,
    onPageClick,
    onNextClick,
    onPreviousClick,
  } = usePaginationContext()

  return (
    <Pagination
      currentPage={currentPage}
      maxPage={numOfPages}
      maxToShowAll={maxToShowAll}
      onPageClick={onPageClick}
      onNextClick={onNextClick}
      onPreviousClick={onPreviousClick}
    />
  )
}
