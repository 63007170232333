import React from 'react'

import styles from './scss/article.module.scss'

interface Props {
  tags: string[]
  isDetail?: boolean
}

export const ArticleTags: React.FC<Props> = ({ tags, isDetail = false }) => (
  <div className={styles.tags}>
    {tags.map(tag => (
      <div
        key={tag}
        className={`${styles.tag} ${isDetail ? styles.tagDetail : ''} light-copy pink`}
      >
        {tag}
      </div>
    ))}
  </div>
)
